.videosContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-top: 20px;
  background-color: #DADBDD;
  @media screen and (min-width: 1200px) {
    padding: 3% 10%;
    //flex-direction: row;
    flex-wrap: wrap;
  }
}

iframe {
  width: 600px;
  height: 300px;
}

.player {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.videoFigure {
  display: flex;
  position: relative;

  flex-direction: column;
  justify-content: center;
  align-content: center;
  align-items: center;
  margin: 20px auto;
  h1 {
    font-size: 16px;
    font-weight: 900;
    margin: 10px 0;
  }

  p {
    font-size: 14px;
    color: #424242;
    margin: 5px 0;
  }


}

.header {
  color: #091368;
  font-family: Karla;
  font-size: 32px;
  font-weight: 700;
  text-align: center;
  @media screen and (min-width: 1200px) {
    font-size: 48px;
    letter-spacing: 6.86px;
    line-height: 56px;
  }
}

.wrapper {
  display: flex;
  margin: 30px;
  flex-direction: column;
  @media screen and (min-width: 1200px) {
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-evenly;
  }
}

.singleVideoContainer {
  display: flex;
  justify-content: center;
  height: 200px;
  background-color: #C8C8C8;
  margin: 10px 0;
  position: relative;
  width: 300px;

  img {
    display: inherit;
    height: 94px;
    width: 94px;
    margin: 0 auto;
    position: absolute;
    top: 50px;
    justify-content: center;
    align-items: center;
  }

  @media screen and (min-width: 1200px) {
    width: 350px;
    margin: 20px 50px 20px 0;
  }
}