#select__league {
  display: flex;
  //background-color: #F2F2F2;
  height: 65px;
  justify-content: space-around;
  align-items: center;
  //font-family: Titillium-SemiBold;
  font-family: Karla, 'sans-serif';
  overflow: hidden;
  box-sizing: border-box;
  margin: 10px;

  input {
    padding: 10px;
    margin-right: 10px;
    box-sizing: border-box;
    width: 100% !important;
    font-size: 16px;
    //font-family: Titillium;
    line-height: normal;
    border-radius: 5px;
    height: 34px;
    border: 1px solid #D0D0D0;
    //margin: 10px;
    -webkit-appearance: none;
    @media screen and (min-width: 1200px) {
      font-size: 20px;
    }
  }


  .select__league__clear__button {
    position: absolute;
    right: 30px;
    cursor: pointer;
    color: red;
    //font-family: Titillium-Bold;
    font-size: 16px;
  }

  .select__league__container {
    display: flex;
    width: 100%;
    align-items: center;
    position: relative;
  }

  .select {
    color: #000;
    font-size: 18px;
    letter-spacing: 0.5px;
    line-height: 25px;
    text-align: center;
    margin-right: 15px;
  }

  .selectContainer {
    margin: 10px;
  }

  .dropdown {
    padding: 0 50px 0 10px;
    width: 100px !important;
    font-size: 16px;
    font-family: Karla, 'sans-serif';
    //font-family: Titillium-SemiBold;
    line-height: 1;
    border-radius: 5px;
    height: 34px;
    background: url("../../Assets/images/dropdown.png") no-repeat right #FFF;
    border: 1px solid #D0D0D0;
    -webkit-appearance: none;
    background-size: 13%;
    background-position-x: 75px;
    @media screen and (min-width: 1200px) {
      //font-family: Titillium-Bold
      font-family: Karla, 'sans-serif';

    }
  }

  .desktopOnly {
    display: none;
    @media screen and (min-width: 1200px) {
      display: flex;
    }
  }
}
