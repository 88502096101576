#liveScoresDetails {
  display: flex;
  //font-size: 24px;
  flex-direction: column;
  width: 100%;
  min-height: 150px;
  justify-content: center;
  // position: relative;

  .normal {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    flex: 1;
    width: 100%;
  }

  .overlay_timer {
    display: flex;
    position: absolute;
    background-color: #000;
    color: white;
    font-size: 14px;
    justify-content: center;
    align-items: center;
    width: 100%;
    z-index: 1;
    height: 30px;
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
    opacity: 0.8;
    bottom: 0;
    @media screen and (min-width: 768px) {
      font-weight: bolder;
      font-size: 16px;
    }
  }

  .overlay_away,
  .overlay_home {
    display: flex;
    flex-direction: column;
    position: absolute;
    background-color: #2a9d8f;
    color: white;
    font-size: 80px;
    justify-content: center;
    align-items: center;
    //height: 100%;
    width: 50%;
    z-index: 1;
    height: 330px;
    //margin: 10px 0;
    //border-radius: 8px;
    opacity: 0.7;
  }

  .overlay_away {
    border-top-left-radius: 8px;
    border-bottom-left-radius: 8px;
    border-right: 1px solid white;
  }

  .overlay_home {
    right: 0;
    border-top-right-radius: 8px;
    border-bottom-right-radius: 8px;
    border-left: 1px solid white;
  }

  @media screen and (min-width: 1200px) {
    font-size: 24px;
    .location-info {
      font-size: 19.2px;
    }
    .team-name {
      font-size: 22px;
      margin-top: 10px;
    }
    .team-mascot {
      font-size: 24px;
    }
    .team-record {
      font-size: 14px;
    }
    .game-scores-result {
      font-size: 45px;
    }
    .detail-game-score.away-winner,
    .first-cell {
      font-size: 16px;
    }
  }

  div.game-info {
    box-sizing: border-box;
    //width: 90%;
    font-weight: 400;
    transition: all 0.4s ease 0s;
    padding: 20px 0px 15px;
  }

  #game-final {
    font-size: 12px;
    @media screen and (min-width: 768px) {
      font-size: 24px;
    }
  }

  div.game-info__status {
    box-sizing: border-box;
    text-align: center;
  }

  div.location-info {
    box-sizing: border-box;
    font-size: 13.2px;
    color: rgb(0, 0, 0);
    display: flex;
    flex-direction: column;
    align-items: center;

    div.venue-info {
      width: 50%;
      display: flex;
      flex: 1;
      justify-content: center;
      text-align: center;
      margin: 5px 0;
      line-height: 15px;
    }
  }

  div.season-type {
    width: 100%;
    display: flex;
    margin: 0 auto 5px auto;
  }
  div.game-info__teams.away-winner {
    box-sizing: border-box;
    justify-content: center;
    display: flex;
    align-items: center;
  }

  div.team-name-and-ranking {
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    //width: 145px;
    align-items: center;
    padding-right: 5px;
    justify-content: center;
  }

  div.team-name {
    box-sizing: border-box;
    font-size: 15px;
    text-align: right;
    color: rgb(0, 0, 0);
    font-weight: 500;
  }

  div.team-mascot {
    box-sizing: border-box;
    font-size: 18px;
    font-weight: 700;
    text-align: right;
    color: rgb(0, 0, 0);
  }

  div.team-record {
    box-sizing: border-box;
    font-variant: no-common-ligatures no-discretionary-ligatures
      no-historical-ligatures no-contextual;
    font-weight: 400;
    margin: 0;
    font-size: 14px;
    color: rgb(87, 87, 87);
    min-height: 12px;
    text-align: right;
    padding-top: 0;
    padding-right: 0;
    white-space: nowrap;
    position: relative;
    line-height: 25px;
  }

  div.game-scores-result {
    box-sizing: border-box;
    font-size: 20px;
    font-weight: 700;
    color: rgb(0, 0, 0);
  }

  div.aTeam-info.team-info {
    box-sizing: border-box;
    display: flex;
    align-items: center;
  }

  div.hTeam-info.team-info {
    box-sizing: border-box;
    display: flex;
    align-items: center;
    flex-direction: row-reverse;
  }

  .game-info span {
    box-sizing: border-box;
    padding: 0 5px;
    @media screen and (min-width: 1200px) {
      padding: 0 15px;
    }
  }

  div.detail-game-score.away-winner {
    box-sizing: border-box;
    display: flex;
    justify-content: center;
    font-size: 16px;
    padding-top: 10px;
  }

  div.header-periods {
    box-sizing: border-box;
    display: flex;
    border-bottom: 1px solid rgb(221, 221, 221);
    padding-bottom: 3px;
    margin-bottom: 5px;
    color: rgb(133, 133, 133);
    font-weight: 500;
    font-size: 12px;
    width: 100%;
    justify-content: space-evenly;

    @media screen and (min-width: 768px) {
      display: flex;
      justify-content: center;
    }
  }

  span.period-number {
    box-sizing: border-box;
    @media screen and (min-width: 768px) {
      width: 35px;
      text-align: right;
    }
  }

  span.total-number {
    box-sizing: border-box;
    @media screen and (min-width: 768px) {
      margin-left: 8px;
      text-align: right;
      width: 35px;
    }
  }

  span.period-score {
    box-sizing: border-box;
    font-size: 12px;
    text-align: left;
    @media screen and (min-width: 768px) {
      text-align: right;
      width: 35px;
    }
  }

  span.total-score {
    box-sizing: border-box;
    text-align: right;
    font-size: 12px;
    @media screen and (min-width: 768px) {
      margin-left: 8px;
      width: 35px;
    }
  }

  div.away-score.team-periods-scores {
    box-sizing: border-box;
    color: rgb(133, 133, 133);
    //color: rgb(0, 0, 0);
    font-weight: 700;
    align-items: center;
    display: flex;
    width: 100%;
    justify-content: space-evenly;
    @media screen and (min-width: 768px) {
      justify-content: center;
    }
  }

  div.home-score.team-periods-scores {
    box-sizing: border-box;
    //display: grid;
    color: rgb(133, 133, 133);
    font-weight: 700;
    align-items: center;
    display: flex;
    width: 100%;
    justify-content: space-evenly;
    @media screen and (min-width: 768px) {
      justify-content: center;
    }
  }

  span.aTeam-name-cell.first-cell {
    box-sizing: border-box;
    height: 100%;
    font-weight: 700;
    font-size: 12px;
    width: 50px;
  }

  span.hTeam-name-cell.first-cell {
    box-sizing: border-box;
    height: 100%;
    font-weight: 700;
    font-size: 12px;
    width: 50px;
  }

  span.first-cell {
    -webkit-tap-highlight-color: transparent;
    -webkit-text-size-adjust: 100%;
    line-height: 1.42857143;
    font-family: -apple-system, BlinkMacSystemFont, Roboto, Arial,
      Helvetica Neue, Helvetica, sans-serif;
    -webkit-font-smoothing: subpixel-antialiased;
    font-size: 16px;
    color: #858585;
    box-sizing: border-box;
    //outline: rgba( 255, 0, 0, .15 ) solid 1px !important;
    height: 100%;
    font-weight: 700;
    width: 50px;
  }
}
