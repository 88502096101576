@import "reset";
@import "variables";

.Marquee {
  overflow: hidden !important;
  @media screen and (min-width: 1200px) {
    width: 100%;
  }
}

.MarqueeContent {
  display: inline-block;
}

#menuToggle {
  display: flex;
  flex-direction: column;
  position: fixed;
  top: 25px;
  left: 25px;
  z-index: 50;
  -webkit-user-select: none;
  user-select: none;
  background-color: white;
  padding: 10px;
  @media screen and (min-width: 1200px) {
    display: none;
  }
}

#menuToggle input {
  display: flex;
  width: 40px;
  height: 32px;
  position: absolute;
  cursor: pointer;
  opacity: 0;
  z-index: 2;
}

#menuToggle span {
  display: flex;
  width: 35px;
  height: 4px;
  margin-bottom: 5px;
  position: relative;
  background: #091368;
  border-radius: 3px;
  z-index: 1;
  transform-origin: 5px 0;
  transition: transform 0.5s cubic-bezier(0.77, 0.2, 0.05, 1),
    background 0.5s cubic-bezier(0.77, 0.2, 0.05, 1), opacity 0.55s ease;
}

#menuToggle span:first-child {
  transform-origin: 0% 0%;
}

#menuToggle span:nth-last-child(2) {
  transform-origin: 0% 100%;
}

#menuToggle input:checked ~ span {
  opacity: 1;
  transform: rotate(45deg) translate(-3px, -1px);
  background: #36383f;
  background: white;
}

#menuToggle input:checked ~ span:nth-last-child(3) {
  opacity: 0;
  transform: rotate(0deg) scale(0.2, 0.2);
}

#menuToggle input:checked ~ span:nth-last-child(2) {
  transform: rotate(-45deg) translate(0, -1px);
}

#menu {
  position: fixed;
  box-shadow: 0 0 10px #85888c;
  margin: 0 0 0 -50px;
  padding: 35px;
  padding-top: 100px;
  -webkit-font-smoothing: antialiased;
  transform-origin: 0% 0%;
  overflow: auto;
  transform: translate(-100%, 0);
  transition: transform 0.5s cubic-bezier(0.77, 0.2, 0.05, 1);
  top: 0;
  height: 100vh;
}

#menu li {
  padding: 10px 0;
  transition-delay: 2s;
  font-size: 16px;
  display: flex;
  align-items: center;

  &:hover {
    cursor: pointer;
  }
}

#menuToggle input:checked ~ ul {
  transform: none;
}

*,
*:after,
*:before {
  margin: 0;
  padding: 0;
  box-sizing: inherit;
}

html {
  font-size: 16px;
}

body {
  box-sizing: border-box;
  width: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  position: relative;
}

a {
  text-decoration: none;
  color: black;
}

.scroll-top {
  font-size: 25px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  z-index: 49 !important;
  right: 40px;
  bottom: 40px;
  visibility: hidden;

  width: 45px;
  height: 45px;

  transition: 0.3s;
  text-align: center;

  opacity: 0;
  color: #fff;
  border: none;
  border-radius: 100px;
  background: #091368;
  box-shadow: 0 0 15px 0 rgba(50, 50, 50, 0.2);

  &.show {
    visibility: visible;
    opacity: 1;
  }

  &:hover {
    color: #0033ff;
    background: #fff;
    cursor: pointer;
  }

  &:focus {
    outline: none;
  }

  @media screen and (min-width: 1200px) {
    left: 40px;
  }
}

.active {
  color: #cc9e59 !important;
}

.modal {
  position: fixed; /* Stay in place */
  //display: none; /* Hidden by default */
  z-index: 2; /* Sit on top */
  //padding-top: 100px; /* Location of the box */
  left: 0;
  top: 0;
  width: 100%; /* Full width */
  height: 100%; /* Full height */
  overflow: auto; /* Enable scroll if needed */
  background-color: rgb(0, 0, 0); /* Fallback color */
  background-color: rgba(0, 0, 0, 0.4); /* Black w/ opacity */
  @media screen and (min-width: 1200px) {
    padding-top: 20px;
  }
}

.betCalcModalContent {
  width: 90%;
  //height: 80%;
  //background-color: #091368;
  margin: auto;
  padding: 20px;
  align-items: center;
  @media screen and (min-width: 768px) {
    width: 500px;
  }
}

/* Modal Content */
.modal-content {
  background-color: #091368;
  margin: auto;
  padding: 20px;
  width: 100%;
  height: 100%;
  overflow: hidden;
  //border-radius: 8px;
  @media screen and (min-width: 1200px) {
    height: 98%;
    width: 80%;
  }

  img {
    margin: 0 auto;
    display: flex;
    height: auto;
    width: 200px;
    @media screen and (min-width: 1200px) {
      width: 500px;
      margin: 20px auto;
    }
  }
}

/* The Close Button */
.close {
  color: #aaaaaa;
  float: right;
  font-size: 28px;
  font-weight: bold;
}

.close:hover,
.close:focus {
  color: red;
  text-decoration: none;
  cursor: pointer;
}

.fullStory {
  color: black !important;
  overflow: hidden;
  height: 100%;
  //padding: 20px;
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    text-align: center;
    font-size: 42px;
    font-weight: bold;
    text-transform: uppercase;
    margin: 20px 0;
  }

  p,
  div {
    font-size: 20px;
    color: black !important;
    line-height: 30px;
    margin: 25px 0;
    letter-spacing: 2px;
  }

  a {
    color: black;
    pointer-events: none;
  }
}

.view-story {
  background: transparent;
  /* color: blue; */
  padding: 10px;
  border: none;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex: 1;
  cursor: pointer;
  @media screen and (min-width: 1200px) {
    border-radius: 5px;
    width: 200px;
    height: 50px;
    margin: 0 20px;
  }
}
