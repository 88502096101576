@tailwind base;
@tailwind components;
@tailwind utilities;

#root {
  height: 100vh !important;
}

html {
  overflow-x: hidden;
}

.timeline-skeleton {
  display: flex;
  flex-direction: column;
  max-height: 2000px;
  background-color: #fff;
  border-radius: 12px;
  border: 1px solid rgb(207, 217, 222);
  overflow: hidden;
  height: 2000px;
}

.timeline-header {
  font-size: 20px;
  font-weight: 700;
  color: rgb(15, 20, 25);
  padding: 12px;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica,
    Arial, sans-serif;
  border-bottom: 1px solid rgb(207, 217, 222);
}

.timeline-tweet {
}
