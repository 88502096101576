$nav__item__color: "#353535";

.container, .ul {
  display: flex;
}

.ul {
  align-items: center;
}

.container {
  justify-content: center;
}

.remove__underline {
  text-decoration: none;
}

a:visited {
  //color: black;
}

.nav__item {
  margin: .5rem;
  //width: 100%;
  display: none;
  @media screen and (min-width: 1200px) {
    display: flex;
    margin: 0 25px;
    font-size: 12px;
    height: 13px;
    color: #353535;
    font-weight: 700;
    letter-spacing: 1.57px;
    line-height: 13px;
    text-align: center;

  }
}


.parallelogram {
  @media screen and (min-width: 1200px) {
    width: 241.75px;
    height: 49px;
    background: #091368;
    color: white;
    justify-content: center;
    display: flex;
    align-items: center;
    transform: skew(-20deg);
    span {
      transform: skew(20deg);

    }
    &:after {
      //content: "GET THE LATEST NEWS!";
    }
  }
}
