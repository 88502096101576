#auto__scroll {
  box-sizing: border-box;
  transform: translateZ(0px);
  font-family: Titillium-Bold;
  //position: fixed;
  left: 0;
  top: 0;
  height: 50px;
  width: 100%;
  margin-left: 0;
  padding-left: 0;
  overflow: hidden;
  //padding: 40px;
  background: rgb(37, 37, 37);
  color: rgb(255, 255, 255);
  //z-index: 900;
  white-space: nowrap;
  overflow-wrap: break-word;
  display: flex;
  align-items: center;
  //z-index: 1;

  @media screen and (min-width: 1200px) {
    padding: 40px;
    a {
      font-size: 20px;
    }
  }

  a:visited {
    color: white !important;
  }

  a {
    color: white;
    //font-size: 20px;
    &:visited {
      color: white;
    }
  }

  .auto__scroll__letters {
    box-sizing: border-box;
    border: 0 solid #e2e8f0;
    overflow-wrap: break-word;
    position: relative;

    .link {
      &:hover {
        animation-play-state: paused;
      }
    }

    .auto__scroll__away__team {
      margin: 5px;
    }
  }

  @media screen and (min-width: 768px) {
    bottom: 0 !important;
    top: auto;
  }

}
