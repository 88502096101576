#privacy {
  display: flex;
  flex-direction: column;
  background-color: rgba(218, 219, 221, 0.75);

  // .container {
  //   margin: 20px;
  // }

  // h1 {
  //   font-size: 24px;
  //   text-align: center;
  //   margin: 10px;
  // }

  // p {
  //   font-size: 18px;
  //   line-height: 30px;
  // }

  // ol {
  //   list-style: circle;
  //   justify-content: center;
  //   display: flex;
  //   flex-direction: column;
  //   padding: 25px;

  //   li {
  //     font-size: 16px;
  //     line-height: 24px;
  //   }
  // }

  // @media screen and (min-width: 1200px) {
  //   .container {

  //     margin: 20px 10%;
  //   }
  // }
}