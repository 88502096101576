#liveScores {
  .react-calendar {
    margin: 0 auto;
    @media screen and (min-width: 1200px) {
      width: 500px;
    }
  }

  .bg-calendar {
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    min-height: 400px;
  }

  display: flex;
  flex-direction: column;
  position: relative;
  font-family: Karla, "sans-serif";
  background-color: #dadbdd;
  min-height: 500px;
  @media screen and (min-width: 1200px) {
    //padding: 20px 0;
  }

  h1 {
    font-size: 20px;
    //color: #FFF;
    font-weight: bold;
    //margin: 10px;
    text-transform: uppercase;
    text-align: center;
    @media screen and (min-width: 1200px) {
      font-size: 32px;
    }
  }

  .versus {
    font-size: 18px;
  }

  .liveScoresText {
    font-family: Karla;
    @media screen and (min-width: 768px) {
      font-size: 48px;
      letter-spacing: 6.86px;
      font-weight: 700;
      line-height: 56px;
      color: #091368;
      opacity: 0.7;
    }
  }

  .events {
    overflow-x: hidden;
    display: grid;
    min-height: 330px;
    grid-template-columns: 1fr;
    @media only screen and (max-width: 1200px) and (min-width: 1000px) {
      padding: 50px;
    }
    @media screen and (min-width: 1200px) {
      flex-direction: row;
      flex-wrap: wrap;
      justify-content: center;
      grid-template-columns: 50% 50%;
      padding: 30px 150px;
    }
  }

  .event__container {
    height: 330px;
    background: white;
    margin: 10px;
    border-radius: 8px;
    display: flex;
    justify-content: center;
    align-items: center;
    box-shadow: 0 0 15px rgba(0, 0, 0, 0.1);
    position: relative;
  }

  .ribbon {
    width: 150px;
    height: 150px;
    overflow: hidden;
    position: absolute;

    @media screen and (min-width: 768px) {
      z-index: 2;
    }
  }

  .ribbon::before,
  .ribbon::after {
    position: absolute;
    z-index: -1;
    content: "";
    display: block;
    border: 5px solid #2980b9;
  }

  .ribbon span {
    position: absolute;
    display: block;
    width: 225px;
    padding: 15px 33px;
    background-color: #3498db;
    box-shadow: 0 5px 10px rgba(0, 0, 0, 0.1);
    color: #fff;
    font: 700 14px/1 "Lato", sans-serif;
    text-shadow: 0 1px 1px rgba(0, 0, 0, 0.2);
    text-transform: uppercase;
    text-align: center;
  }

  .ribbon-top-right {
    top: -10px;
    right: -10px;
  }

  .ribbon-top-right::before,
  .ribbon-top-right::after {
    border-top-color: transparent;
    border-right-color: transparent;
  }

  .ribbon-top-right::before {
    top: 0;
    left: 0;
  }

  .ribbon-top-right::after {
    bottom: 0;
    right: 0;
  }

  .ribbon-top-right span {
    left: -25px;
    top: 30px;
    transform: rotate(45deg);
    // z-index: 2;
  }

  .md-lg-only {
    display: none !important;
    @media screen and (min-width: 768px) {
      display: flex !important;
    }
  }

  .mobile-only {
    display: inline;
    @media screen and (min-width: 768px) {
      display: none;
    }
  }

  .dateText {
    width: 350px;
    text-align: center;
    margin: 0 auto;
    background-color: #091368;
    color: white;
    height: 62px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
    max-width: 100%;
    @media screen and (device-aspect-ratio: 40/71) {
      width: 320px;
    }

    @media screen and (min-width: 1200px) {
      width: 500px;
    }
  }

  .selectLeagueContainer {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    background-color: rgba(0, 10, 38, 0.75);
    color: white;
    margin: 0 0 20px 0;

    ul {
      display: flex;
      align-items: center;

      li {
        font-size: 12px;
        margin-right: 15px;

        &:hover {
          cursor: pointer;
        }
      }

      @media screen and (min-width: 768px) {
        margin: 15px;
      }
      @media screen and (min-width: 1200px) {
        li {
          font-size: 20px;

          &:first-child {
            font-size: 24px;
            cursor: no-drop;
          }
        }
      }
    }
  }

  .leagueInput {
    width: 100%;
    padding: 10px;
    border: none;
    font-size: 16px;
    box-shadow: 0 0 15px rgba(0, 0, 0, 0.1);
    color: black;
  }

  .sportsLogo {
    width: 80px;
    height: auto;
    //margin-right: 15px;
    @media screen and (min-width: 768px) {
      //width: 150px;
      margin: 0;
    }
  }

  .leagueLogo {
    width: 80px;
    height: auto;
    margin-bottom: 20px;
    @media screen and (min-width: 768px) {
      width: 150px;
      margin: 20px 0;
    }
  }
}
