#home__page {
  display: flex;
  flex-direction: column;
  // min-height: 100vh;

  .home__page__container {
    flex: 1
  }

  .home__page__no__data__container {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .home__page__loading__container {
    display: flex;
    justify-content: center;
    align-items: center;
    // height: 400px;
    margin: 20px;
    span {
      color: white;
      font-size: 1.5rem;
    }
    //margin-top: 50%;
    //margin-bottom: 50%
  }

  .noData {
    width: 300px;
    height: auto;
    @media screen and (min-width: 768px) {
      width: 400px;
    }

    @media (max-width: 1200px) and (min-width: 1024px) {
      //width: 800px;
    }

    @media screen and (min-width: 1200px) {
      //width: 600px;
    }
  }
}
