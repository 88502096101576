.headerContainer {
  position: relative;
  display: flex;
  height: 250px;
  width: 100%;
  background-image: url(../../Assets/images/logo.png),
    url(../../Assets/images/stadium-lights.png);
  background-repeat: no-repeat;
  background-position: center, center;
  @media screen and (min-width: 1200px) {
    background-position: center 15px, center;
  }
}

.twitterFigure {
  @media screen and (min-width: 1200px) {
    z-index: 5;
  }
}

.subMenu {
  display: grid;
  grid-template-columns: 1fr 1fr;
  //grid-gap: 5px;
  background-color: #000a26;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  //flex-basis: 50%;
  a {
    margin: 5px;
  }
  img {
    height: 100%;
    width: 100%;
  }

  @media screen and (min-width: 600px) {
    display: flex;
  }

  @media screen and (min-width: 1200px) {
    display: flex;
    a {
      margin: 10px;
    }
    img {
      width: auto;
    }
  }
}

.containerLayout {
  display: flex;
  flex-direction: column;
  background-color: rgba(218, 219, 221, 0.75);
  @media screen and (min-width: 1200px) {
    flex-direction: row;
    justify-content: space-evenly;
  }
}

.container {
  @extend .containerLayout;
  @media screen and (min-width: 1200px) {
    padding: 3% 10%;
  }
}

.hotOffPressContainer {
  @media screen and (min-width: 1200px) {
    z-index: 1;
    max-width: 800px;
    min-height: 800px;
  }
}

.sportsLines {
  position: fixed;
  display: none;
  border-top-right-radius: 25px;
  border-top-left-radius: 25px;
  padding: 30px;
  color: #000a26;
  font-family: Karla;
  font-size: 16px;
  font-weight: 700;
  letter-spacing: 2.29px;
  line-height: 19px;
  text-align: center;
  transform: rotate(-90deg);
  transform-origin: 100%;
  text-transform: uppercase;
  align-items: center;
  background-color: #cacaca;
  z-index: 1;
  @media screen and (min-width: 1200px) {
    display: flex;
    right: 2%;
    &:hover {
      cursor: pointer;
    }
  }
}

.bannerAd {
  height: auto;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  @media screen and (min-width: 1200px) {
    padding: 0;
  }
}

.bannerAdFooter {
  @extend .bannerAd;
  padding: 0;
  @media screen and (min-width: 1200px) {
    width: 100%;
    height: auto;
    padding: 3% 10%;
  }
}

.hotOffThePress {
  position: relative;
  color: #091368;
  font-size: 24px;
  font-weight: 700;
  text-transform: uppercase;
  letter-spacing: 2.43px;
  line-height: 28px;
  text-align: center;
  margin: 30px 0;
  display: flex;
  justify-content: space-between;
  // padding: 0 20px;
  @media screen and (min-width: 1200px) {
    text-align: left;
    padding: 0;
  }
}

.whatsNews {
  color: #091368;
  font-size: 24px;
  font-weight: 700;
  text-transform: uppercase;
  letter-spacing: 2.43px;
  line-height: 28px;
  text-align: center;
  margin: 10px 0;
}

.twitterFeed {
  display: flex;
  flex-direction: column;
  @media screen and (min-width: 1200px) {
    margin-left: 5%;
    z-index: 0;
  }
}

.twitterFeedHeading {
  display: flex;
  justify-content: center;
  align-items: center;
  @media screen and (min-width: 1200px) {
    flex-direction: column;
  }
}

.twitterLogo {
  height: 72px;
  background-color: #091368;
  width: 72px;
  border-radius: 100px;
  padding: 10px;
  margin: 10px;
  @media screen and (min-width: 1200px) {
    margin: 0 auto;
  }
}

.subNav {
  display: none;
  position: absolute;
  bottom: 0;
  height: 60px;
  opacity: 0.75;
  background-color: #000a26;
  justify-content: center;
  width: 100%;

  @media screen and (min-width: 1200px) {
    display: flex;
  }

  ul {
    display: inherit;
    align-items: center;

    li {
      a {
        color: white;
      }
      text-transform: uppercase;
      font-weight: 700;
      letter-spacing: 2px;
      line-height: 16px;
      font-family: Karla;
      //margin: 7px;
      width: 100%;
      @media screen and (min-width: 1200px) {
        font-size: 14px;
        margin: 15px;
        width: auto;
      }
    }
  }
}

.twitterContent {
  // height: 669px;
  // width: 341px;
  // margin: 1.5em 15px;
  overflow-y: hidden;
  @media screen and (min-width: 1200px) {
    // width: 400px;
    // height: 100%;
  }
}

// .twitterContent {
//   height: 669px;
//   background-color: #FFF;
//   border-radius: 5px;
//   margin: 1.5em 15px;
//   overflow-y: hidden;

//   @media screen and (min-width: 1200px) {
//     width: 500px;
//     height: 100%;
//   }

//   .hr {
//     box-sizing: border-box;
//     height: 1px;
//     border: 1px solid #E3E4E6;
//     margin: 10px 30px;
//   }

//   figure {
//     display: flex;
//     flex-direction: column;
//     padding: 2em;
//     direction: ltr;

//     img {
//       height: 50px;
//       width: 50px;
//     }

//     p, span {
//       margin: 0 10px;
//       font-family: Karla;
//     }

//     p {
//       color: #000A26;
//       font-size: 14px;
//       font-weight: 700;
//       line-height: 1.3em;
//     }

//     span {
//       color: #B9B9B9;
//       font-size: 10px;
//       font-weight: 700;
//       letter-spacing: 1.43px;
//       line-height: 12px;
//     }
//   }
// }

.circles {
  position: absolute;
  left: 50px;
  z-index: -1;
  display: none;
  @media screen and (min-width: 1200px) {
    display: flex;
  }
}

.circlesRight {
  position: absolute;
  z-index: -1 !important;
  display: none;
  right: 55px;
  bottom: -800px;
  @media screen and (min-width: 1200px) {
    display: flex;
  }
}

#full_Story_modal_body {
  * {
    margin: 10px 0;
  }
  a {
    color: rgb(156 163 175) !important;
    text-decoration: underline;
  }
}

.activeImg {
  border: 2px solid #cc9e59;
}
