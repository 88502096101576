#tos {
  display: flex;
  flex-direction: column;
  min-height: 100%;
  background-color: rgba(218, 219, 221, 0.75);

  // .container {
  //   flex: 1;
  //   margin: 20px;
  //   @media screen and (min-width: 1200px) {
  //     // margin: 20px 75px;
  //   }

  //   h6 {
  //     font-weight: bolder;
  //     text-align: center;
  //     font-size: 20px;
  //     text-decoration: underline;
  //     margin: 15px;
  //   }
  // }

  // h1 {
    // margin: 20px;
    // text-align: center;
    // font-weight: bold;
    // font-size: 24px;
  // }

  div {
    // line-height: 25px;
    // letter-spacing: 0.73px;
    font-size: 18px;

    ul {
      li {
        margin: 20px 0;
      }
    }
  }


}
