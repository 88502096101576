.footerContainer {
  display: flex;
  justify-content: center;
  margin: 20px 0;
  flex-direction: column;
  align-items: center;

  @media screen and (min-width: 1200px) {
    flex-direction: row;
  }

  ul {
    display: inherit;
    align-items: center;
    overflow: hidden;
    flex-direction: column;
    justify-content: center;
    @media screen and (min-width: 1200px) {
      flex-direction: row;
    }

    li {
      text-transform: uppercase;
      font-weight: 700;
      letter-spacing: 2px;
      text-align: center;
      line-height: 16px;
      font-family: Karla;
      font-size: 12px;
      box-sizing: border-box;
      margin: 10px;
      &:hover {
        cursor: pointer;
      }
      @media screen and (min-width: 1200px) {
        font-size: 14px;
        margin: 50px;
      }
    }
  }
}

.logo {
  height: auto;
  width: 75px;
  @media screen and (min-width: 1200px) {
    height: auto;
    width: 141.5px;
  }
}

.tradeMark {
  background-color: #000A26;
  height: 57px;
  display: flex;
  align-items: center;
  justify-content: center;
  span {
    color: #FFF;
    font-family: Karla;
    font-size: 11px;
    line-height: 18px;
    text-align: center;
    @media screen and (min-width: 1200px) {
      font-size: 12px;
      letter-spacing: 1.71px;
    }
  }
}
