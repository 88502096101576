#current__sports__lines {
  font-family: Titillium, sans-serif;
  overflow-x: hidden;

  h2 {
    color: white;
    font-size: 18px;
    font-weight: 600;
    letter-spacing: 0.5px;
    line-height: 18px;
    text-align: center;
    margin: 18px;
    @media screen and (min-width: 1200px) {
      font-size: 30px;
    }
  }

  .current__sports__lines__team__1 span:nth-child(even) {
    background-color: #F2F2F2;
  }

  .current__sports__lines__team__2 span:nth-child(even) {
    background-color: #F2F2F2;
  }

  span, h6 {
    color: #000;
    font-size: 75%;
    letter-spacing: 0.31px;
    width: 100%;
    text-align: left;
    padding: 1%;
    display: flex;
    align-items: center;
    box-sizing: border-box;
    @media screen and (min-width: 1200px) {
      font-size: 18px;
    }
  }

  span:first-child {
    flex: 1 0 30%;
  }

  p {
    color: #000;
    font-size: 55%;
    @media screen and (min-width: 1200px) {
      font-size: 80%;
    }
  }

  .current__sports__lines__line {
    background-color: #C8C8C8;
    height: 1px;
    border: none;
  }

  .current__sports__lines__view__details {
    background-color: transparent;
    color: black;
    height: 1px;
    border: none;

    :visited {
      color: black;
    }
  }

  .current__sports__lines__no__data__container {
    display: flex;
    align-items: center;
    justify-content: center
  }

  .current__sports__lines__no__data {
    width: auto;
    height: 400px;
  }

  .current__sports__lines__container {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    margin: 15px 2%;
  }

  .current__sports__lines__desktop__only {
    display: none;
    @media screen and (min-width: 1200px) {
      display: flex;
    }
  }


  .current__sports__lines__table__content__container {
    margin-bottom: 15px;
  }

  .current__sports__lines__table__content, .current__sports__lines__table__headings {
    width: 95%;
    margin: 0 auto;
    align-items: center;
  }

  .current__sports__lines__table__headings {
    display: flex;

    span {
      color: white;
    }

    span:nth-child(2) {
      width: 475px;
      @media screen and (min-width: 1200px) {
        width: 100%;
      }
    }
  ;
  }

  .current__sports__lines__table__content {
    margin: 20px auto;
    border-radius: 5px;
    background-color: #FFF;
    border-top: 0.5px solid #808080;
    border-left: 0.5px solid #808080;
    border-right: 0.5px solid #808080;
    border-bottom: 0.5px solid #808080;

    .current__sports__lines__team__1, .current__sports__lines__team__2 {
      background-color: #FFF;
      display: flex;
      border-bottom: 0.5px solid #A4A4A4;
      border-top-left-radius: 5px;
      border-top-right-radius: 5px;

      .current__sports__logo {
        width: auto;
        height: 25px;
        display: flex;
        align-self: center;
        justify-content: center;
        margin-right: 5px;
        @media screen and (min-width: 1200px) {
          width: auto;
          height: 50px;
        }
      }

      span:nth-child(2) {
        width: 475px;
        @media screen and (min-width: 1200px) {
          width: 100%;
        }
      }
    }

    .current__sports__lines__team__1 {
      span:last-child {
        border-top-right-radius: 5px;
      }
    }
  }

  .current__sports__lines__table__footer {
    border-radius: 5px;
    background-color: #E8E8E8;
    border: 0.5px solid #808080;
  }

  .current__sports__lines__start__time {
    background-color: #e8e8e8;
    display: flex;
    justify-content: space-between;
    border-bottom-right-radius: 5px;
    border-bottom-left-radius: 5px;
    align-items: center;
    padding: 1%;
    @media screen and (min-width: 1200px) {
      p {
        font-size: 100%;
      }
    }
  }

}


.current__sports__lines__toggle__container {
  position: relative;
  display: inline-block;
  width: 60px;
  height: 30px;
  pointer-events: none;
  user-select: none;
}

.current__sports__lines__toggle__container input {
  opacity: 0;
  width: 100%;
  height: 100%;
  pointer-events: all;
}

.current__sports__lines__slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #808080;
  transition: 0.4s;
  pointer-events: none;
}

.current__sports__lines__slider::before {
  position: absolute;
  content: "";
  height: 16px;
  width: 16px;
  left: 5px;
  bottom: 8px;
  background-color: #fff;
  transition: 0.2s;
  pointer-events: none;
}

.current__sports__lines__slider::after {
  position: absolute;
  content: "H1";
  height: 16px;
  width: 16px;
  right: 10px;
  bottom: 7px;
  transition: 0.2s;
  color: #fff;
  pointer-events: none;
}

input:checked + .current__sports__lines__slider {
  background-color: #71A9F7;
}

input:checked + .current__sports__lines__slider:before {
  transform: translateX(40px);
  left: 0 !important;
}

input:checked + .current__sports__lines__slider:after {
  content: "H2";
  left: 8px;
}

.current__sports__lines__slider.current__sports__lines__round {
  border-radius: 34px;
}

.current__sports__lines__slider.current__sports__lines__round:before {
  border-radius: 50%;
}


*:focus {
  outline: none;
}

input[type="checkbox"]:focus {
  background: transparent;
  box-shadow: none;
  outline: none;
  outline-width: 0;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0) !important;
}


