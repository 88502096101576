.bet__calculator__container {
    font-family: Roboto, 'sans-serif';
    font-size: 16px;
    color: rgb(26, 29, 36);
    padding: 9px 16px;
    border: 1px solid rgb(220, 222, 226);
    vertical-align: baseline;
    text-decoration: none;
    box-sizing: border-box;
    //width: 60%;
    justify-content: center;
    display: flex;
    flex-flow: column nowrap;
    align-items: stretch;
    margin: 20px auto;
    background: white;
    border-radius: 10px;
    position: relative;
    max-width: 400px;
    min-width: 250px;

    h3 {
        color: #091368 !important;
        font-weight: 900;
        text-align: center;
        margin: 10px;
    }
    @media screen and (min-width: 1200px) {
        // width: 400px;
        //margin: 10px auto;
        //height: 350px;
    }
}

.bet__calculator__title {
    font-family: Roboto;
    font-size: 16px;
    color: rgb(26, 29, 36);
    margin: auto;
    padding: 9px 12px;
    border: 0px;
    vertical-align: baseline;
    text-decoration: none;
    box-sizing: border-box;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: rgb(220, 222, 226);
    border-radius: 6px;
    width: 100%;
}


.bet__calculator__input__container {
    font-family: Roboto;
    font-size: 16px;
    color: rgb(26, 29, 36);
    margin: 0px;
    padding: 0px;
    border: 0px;
    max-height: 200px;
    overflow-y: auto;
    vertical-align: baseline;
    text-decoration: none;
    box-sizing: border-box;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 8px;
    margin-left: 7px;
    margin-bottom: 10px;
    flex-direction: column;
}

.bet__calculator__line {
    flex: 1;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    margin: 10px 0;
}

.bet__calculator__input[name="payout"], .bet__calculator__amount__input[name="payoutTotal"] {
    background: #ececec;
    cursor: not-allowed;
}

.bet__calculator__input {
    font-family: Roboto;
    font-size: 16px;
    color: rgb(26, 29, 36);
    margin: 0px;
    padding: 0px;
    border: 1px solid rgb(220, 222, 226);
    vertical-align: baseline;
    text-decoration: none;
    box-sizing: border-box;
    width: 66px;
    height: 32px;
    text-align: end;
    padding-right: 6px;
    background: rgb(255, 255, 255);
    border-radius: 3px;
}

.bet__calculator__span {
    font-family: Roboto;
    font-size: 14px;
    color: rgb(26, 29, 36);
    margin: 0px;
    padding: 0px;
    border: 0px;
    vertical-align: baseline;
    text-decoration: none;
    box-sizing: border-box;
    font-weight: 500;
    line-height: 16px;
}

.bet__calculator__button {
    filter: invert(0%);
    font-family: Roboto;
    font-size: 14px;
    color: rgb(15, 35, 105);
    margin: 0px;
    padding: 0px;
    border: 1px solid rgb(15, 35, 105);
    vertical-align: baseline;
    text-decoration: unset;
    box-sizing: border-box;
    width: auto;
    height: 32px;
    font-weight: 700;
    line-height: 12px;
    border-radius: 6px;
    border-color: rgb(15, 35, 105);
    border-style: solid;
    border-width: 1px;
    background: transparent;
    display: flex;
    align-items: center;
    justify-content: center;
    user-select: none;
    outline: inherit;
    cursor: pointer;
    margin-top: 10px;
    width: 100%;
}

.bet__calculator__button:hover {
    background-color: rgb(15, 35, 105);
    color: #FFF;
}

.bet__calculator__amount__container {
    font-family: Roboto;
    font-size: 16px;
    color: rgb(26, 29, 36);
    margin: 0px;
    padding: 0px;
    border: 0px;
    vertical-align: baseline;
    text-decoration: none;
    box-sizing: border-box;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 8px;
    margin-left: 5px;
}

.bet__calculator__amount__span {
    font-family: Roboto;
    font-size: 14px;
    color: rgb(26, 29, 36);
    margin: 0px;
    padding: 0px;
    border: 0px;
    vertical-align: baseline;
    text-decoration: none;
    box-sizing: border-box;
    font-weight: 500;
    line-height: 16px;
}

.bet__calculator__amount__input {
    font-family: Roboto;
    font-size: 16px;
    color: rgb(26, 29, 36);
    margin: 0;
    padding: 0;
    border: 1px solid rgb(220, 222, 226);
    vertical-align: baseline;
    text-decoration: none;
    box-sizing: border-box;
    width: 80px;
    height: 32px;
    text-align: end;
    padding-right: 6px;
    background: rgb(255, 255, 255);
    border-radius: 3px;
}

.bet__calculator__calculate__button {
    filter: invert(0%);
    font-family: Roboto;
    font-size: 12px;
    color: rgb(255, 255, 255);
    margin: 0px;
    padding: 0px;
    border: 0px;
    vertical-align: baseline;
    text-decoration: none;
    box-sizing: border-box;
    width: calc(50% - 4px);
    height: 40px;
    font-weight: 700;
    line-height: 12px;
    border-radius: 6px;
    border-color: rgb(15, 35, 105);
    border-style: solid;
    border-width: 1px;
    background: rgb(15, 35, 105);
    display: flex;
    align-items: center;
    justify-content: center;
    user-select: none;
    cursor: pointer;
    margin-right: 10px;
}

.bet__calculator__clear__button {
    filter: invert(0%);
    font-family: Roboto;
    font-size: 12px;
    color: rgb(15, 35, 105);
    margin: 0px;
    padding: 0px;
    border: 1px solid rgb(15, 35, 105);
    vertical-align: baseline;
    text-decoration: none;
    box-sizing: border-box;
    width: calc(50% - 4px);
    height: 40px;
    font-weight: 700;
    line-height: 12px;
    border-radius: 6px;
    border-color: rgb(15, 35, 105);
    border-style: solid;
    border-width: 1px;
    background: transparent;
    display: flex;
    align-items: center;
    justify-content: center;
    user-select: none;
    outline: inherit;
    cursor: pointer;
}
