.aboutContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  background-color: #DADBDD;

  figure {
    //display: inherit;
    background: #091368;
    font-family: Karla;
    font-size: 32px;
    font-weight: 700;
    height: 100px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #FFF;
    text-align: center;

    img {
      display: none;
    }

    @media screen and (min-width: 1200px) {
      font-size: 48px;
      letter-spacing: 6.86px;
      line-height: 56px;
      width: 100%;
      height: 100%;
      img {
        display: flex;
      }
    }
  }

  @media screen and (min-width: 1200px) {
    flex-direction: row;
    flex-wrap: wrap;
    padding-bottom: 30px;
  }
}

.wrapper {
  display: flex;
  flex-direction: column;
  position: relative;
  width: 100%;
  //padding: 0 50px;
  .sidebar {
    display: none;
    position: sticky;
    top: 100px;
    align-self: flex-start;
    flex-direction: row;
    margin-top: 21px;
    width: 100%;
    //overflow-x: hidden;
    @media screen and (min-width: 1200px) {
      display: block;

    }

    h6 {
      color: #091368;
      font-size: 18px;
      width: 100%;
      margin: 20px;
      font-weight: 700;

      &:hover {
        cursor: pointer;
      }
    }
  }

  .content {
    display: flex;
    flex-direction: column;
    color: #000A26;
    //line-height: 41px;

    h4, h6, p, ul > li {
      margin: 15px 20px;
      line-height: 21px;
    }

    h4 {
      font-weight: 900;
      color: #091368;
      font-size: 16px;
      display: flex;
    }

    h6 {
      font-weight: 700;
      font-size: 14px;
    }

    p {
      font-size: 12px;
      font-weight: 600;
    }

    ul {
      img {
        display: flex;
        width: 100%;
        height: auto;
        //padding: 50px;
        justify-content: center;
        //margin: 0 auto;
        margin: 10px auto;
      }

      li {
        list-style-type: circle;
        margin: 10px 50px;
        font-weight: 600;
        font-size: 12px;
      }
    }

    table {
      margin: 20px;

      th, tr, td {
        padding: 10px;
        font-size: 14px;
        text-align: center;

        @media screen and (min-width: 768px) {
          font-size: 20px;
        }

        @media screen and (min-width: 1200px) {
          font-size: 24px;
        }
      }
    }

    @media screen and (min-width: 768px) {
      h4 {
        font-size: 28px;
        line-height: 30px;
      }

      h6 {
        line-height: 30px;
        font-size: 22px;
      }

      p, ul > li {
        font-size: 18px;
        line-height: 25px;
      }
    }

    @media screen and (min-width: 1200px) {
      margin: 10px 75px;
      img {
        padding: 30px;
      }

      h4 {
        font-size: 32px;
        font-weight: 900;
      }
      h6 {
        font-size: 26px;
      }
      p, ul > li {
        font-size: 20px;
        line-height: 30px;
      }
    }
  }

  @media screen and (min-width: 1200px) {
    flex-direction: row;
    margin: 50px;
    .content {
      //flex-direction: row;
    }
  }
}


.banner {
  //padding: 3% 15%;
  margin-bottom: 30px !important;
}