.contactContainer {
  display: flex;
  justify-content: center;
  background-color: #DADBDD;
  flex-direction: column;
  z-index: -2;

  form {
    display: inherit;
    flex-direction: column;
    justify-content: center;
    width: 100%;
    padding: 20px;
    margin: 0 auto;
    @media screen and (min-width: 768px) {
      width: 66% !important;
    }
    @media screen and (min-width: 1200px) {
      z-index: 1;
    }

  }
}

.contactUsText {
  font-size: 32px;
  text-align: center;
  color: #091368;
  margin: 30px;
  text-transform: uppercase;
  //margin: 50px 0 0 0;
  @media screen and (min-width: 1200px) {
    font-weight: 700;
  }
}

.label {
  font-size: 18px;
  margin: 10px 0;
}

.input {
  padding: 10px;
}

.textarea {
  padding: 10px;
}

.required {
  color: red;
}

.submitButton {
  font-size: 18px;
  padding: 10px;
  margin: 10px 0;
  background-color: white;
  border-radius: 5px;
  border: 1px solid #091368;

  &:hover {
    background-color: #091368;
    color: white;
    cursor: pointer;
  }
}


figure {
  position: relative;

  .circles {
    position: absolute;
    left: -150px;
    //top: -20px;
    display: none;
    height: 400px;
    //right: 50px;
    @media screen and (min-width: 1200px) {
      display: flex;
      z-index: -1;
    }
  }
}

figure {
  position: relative;

  .circlesRight {
    position: absolute;
    display: none;
    height: 400px;
    right: -157px;
    bottom: -95px;
    @media screen and (min-width: 1200px) {
      display: flex;
      z-index: -1;
    }
  }
}
